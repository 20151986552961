import { clone } from 'lodash';
import moment from 'moment';

const ganttScalesDayTemplate = (date) => {
    if (window.ganttInstance) {
        const dateToStr = window.ganttInstance.date.date_to_str('%d')(date);
        const now = new Date();

        if (
            date.getFullYear() === now.getFullYear() &&
            date.getMonth() === now.getMonth() &&
            date.getDate() === now.getDate()
        ) {
            return `<span class="bg-primary text-secondary-dark px-1 py-1.5 rounded">${dateToStr}</span>`;
        }

        return `<span>${dateToStr}</span>`;
    }
    return '';
};
const zoomLevels = [
    {
        name: 'hours',
        round_dnd_dates: true,
        min_column_width: 30,

        scale_height: 60,
    },
    {
        name: 'days',
        round_dnd_dates: true,
        min_column_width: 60,
        scale_height: 60,
    },
    {
        name: 'weeks',
        round_dnd_dates: false,
        min_column_width: 200,
        scale_height: 60,
    },
    {
        name: 'months',
        round_dnd_dates: false,
        min_column_width: 205,
        scale_height: 60,
    },
    {
        name: 'quarters',
        round_dnd_dates: false,
        min_column_width: 210,
        scale_height: 60,
    },
    {
        name: 'years',
        round_dnd_dates: false,
        min_column_width: 250,
        scale_height: 60,
    },
];

const scaleConfigs = (translation) => [
    // // minutes
    // {
    //     name: 'minutes',
    //     label: 'Minute',
    //     scales: [
    //         { subscale_unit: 'minute', unit: 'hour', step: 1, format: '%H', css: () => 'day_scale' },
    //         { unit: 'minute', step: 1, format: '%H:%i', css: () => 'day_scale' },
    //     ],
    // },
    // hours
    {
        name: 'hours',
        label: 'Heure',
        scales: [
            { subscale_unit: 'hour', unit: 'day', step: 1, format: '%j %M', css: () => 'day_scale' },
            { unit: 'hour', step: 1, format: '%H', css: () => 'day_scale' },
        ],
    },
    // days
    {
        name: 'days',
        label: 'Jour',
        scales: [
            { subscale_unit: 'day', unit: 'month', step: 1, format: '%F %Y', css: () => 'day_scale' },
            {
                unit: 'day',
                step: 1,
                template: ganttScalesDayTemplate,
                css: () => 'day_scale',
            },
        ],
    },
    // weeks
    {
        name: 'week',
        label: 'Semaine',
        scales: [
            { subscale_unit: 'day', unit: 'week', step: 1, format: `${translation('week')}%w - %Y`, css: () => 'day_scale' },
            { unit: 'day', step: 1, format: '%d', css: () => 'day_scale' },
        ],
    },
    // months
    {
        name: 'month',
        label: 'Mois',
        scales: [
            { subscale_unit: 'week', unit: 'month', step: 1, format: '%F %Y', css: () => 'day_scale' },
            { unit: 'week', step: 1, format: `${translation('week')}%w`, css: () => 'day_scale' },
        ],
    },
    // quarters
    {
        name: 'quarter',
        label: 'Trimestre',
        scales: [
            { subscale_unit: 'quarter', unit: 'month', step: 1, format: '%M', css: () => 'day_scale' },
            {
                unit: 'quarter',
                step: 1,
                template(date) {
                    const dateToStr = moment(date).format(`[${translation('quarter')}]Q YYYY`);
                    return `${dateToStr}`;
                },
                css: () => 'day_scale',
            },
        ],
    },
    // years
    {
        name: 'year',
        label: 'Année',
        scales: [
            { subscale_unit: 'month', unit: 'year', step: 1, format: '%Y', css: () => 'day_scale' },
            { unit: 'month', step: 1, format: '%M', css: () => 'day_scale' },
        ],
    },
    // // decades
    // {
    //     scales: [
    //         {
    //             subscale_unit: 'year',
    //             unit: 'year',
    //             step: 10,
    //             template(date) {
    //                 const dateToStr = window.ganttInstance.date.date_to_str('%Y');
    //                 const endDate = window.ganttInstance.date.add(
    //                     window.ganttInstance.date.add(date, 10, 'year'),
    //                     -1,
    //                     'day'
    //                 );
    //                 return `${dateToStr(date)} - ${dateToStr(endDate)}`;
    //             },
    //         },
    //         {
    //             unit: 'year',
    //             step: 100,
    //             template(date) {
    //                 const dateToStr = window.ganttInstance.date.date_to_str('%Y');
    //                 const endDate = window.ganttInstance.date.add(
    //                     window.ganttInstance.date.add(date, 100, 'year'),
    //                     -1,
    //                     'day'
    //                 );
    //                 return `${dateToStr(date)} - ${dateToStr(endDate)}`;
    //             },
    //         },
    //     ],
    // },
];

const GANTT_MIN_COLUMN_WIDTH = 30;
const DEFAULT_COLUMNS = ['name', 'startDate', 'endDate', 'duration', 'durationUnit', 'valid', 'jobs'];
export const GANTT_CUSTOM_PROJECT = 'custom-project'
const setGanttConfigurations = (
    tabPreferences,
    planningSelected,
    modeSelected,
    canAccess,
    translation
) => {
    window.ganttInstance.config.types.customProject = GANTT_CUSTOM_PROJECT;

    window.ganttInstance.i18n.setLocale(localStorage.getItem('lng') ?? 'fr');
    window.ganttInstance.config.row_height = tabPreferences?.gantt_parameters?.rowHeight || 35;
    window.ganttInstance.config.min_task_grid_row_height = 16;
    window.ganttInstance.config.bar_height = (tabPreferences?.gantt_parameters?.rowHeight || 35) * 0.7;

    window.ganttInstance.config.reorder_grid_columns = true;
    window.ganttInstance.config.show_progress = true;
    window.ganttInstance.config.drag_progress = true;
    window.ganttInstance.config.show_unscheduled = true;
    window.ganttInstance.config.open_split_tasks = true;
     // fix mousewheel behaviour for firefox
    const isFirefox = navigator.userAgent.indexOf('Firefox') > -1;
    if (isFirefox) {
        window.ganttInstance.config.wheel_scroll_sensitivity = 0.03;
    }

    if (tabPreferences.gantt_scales) {
        const scale = scaleConfigs(translation).find((i) => i.name === tabPreferences?.gantt_scales);
        window.ganttInstance.config.scales = scale.scales;
        window.ganttInstance.config.scaleName = scale.name;
    } else {
        window.ganttInstance.config.scales = scaleConfigs(translation)[1].scales;
        window.ganttInstance.config.scaleName = scaleConfigs(translation)[1].name;
    }
    window.ganttInstance.config.min_column_width = GANTT_MIN_COLUMN_WIDTH;
    window.ganttInstance.config.scale_height = 72;
    // window.ganttInstance.config.fit_tasks = true;
    // window.ganttInstance.config.auto_types = true;
    window.ganttInstance.config.order_branch = 'marker';
    window.ganttInstance.config.order_branch_free = true;
    window.ganttInstance.config.grid_resize = true;
    window.ganttInstance.config.resize_rows = true;
    window.ganttInstance.config.date_format = (tabPreferences?.gantt_parameters?.hideHours || tabPreferences?.gantt_parameters?.hideHours === undefined)
        ? '%Y-%m-%d'
        : '%Y-%m-%d %H:%i:%s';
    window.ganttInstance.config.readonly = modeSelected === 'archive';
    window.ganttInstance.config.types.folder = 'folder';
    window.ganttInstance.config.types.planning = 'planning';
    window.ganttInstance.config.types.job = 'job';
    // multiple selection
    // window.ganttInstance.config.multiselect_one_level = true;
    window.ganttInstance.plugins({
        fullscreen: true,
        drag_timeline: true,
        multiselect: true,
        marker: true,
        click_drag: modeSelected !== 'archive',
        quick_info: true,
        undo: true
        // critical_path: true,
    });
    window.ganttInstance.config.drag_multiple = true;
    window.ganttInstance.ext.fullscreen.getFullscreenElement = () => document.querySelector('.activities-wrapper');
    // Critical Path displaying
    window.ganttInstance.config.sort = true;
    window.ganttInstance.config.drag_links = canAccess;
    window.ganttInstance.config.drag_timeline = {
        ignore: '.gantt_task_line, .gantt_task_link',
        useKey: 'ctrlKey',
    };

    // added by ronald
    window.ganttInstance.config.round_dnd_dates = false;
    window.ganttInstance.config.time_step = window.ganttInstance.config.scaleName === 'hours' ? 60 : (60 * 24) -1; // when hours step is by hour, else it's by day
    window.ganttInstance.config.autosize = false;
    const isGroupement =
    tabPreferences?.filtered_group?.groupingType === 'custom_fields';
    window.ganttInstance.config.multiselect = !isGroupement;
    window.ganttInstance.config.container_resize_method = 'timeout'
    // get children by resource config, default return real children
    window.ganttInstance.getAllChildren = (taskId, isResource = false) => {
        const children = window.ganttInstance.getChildren(taskId);
        const realChildren = [];
        children.forEach((childId) => {
            const child = window.ganttInstance.getTask(childId);
            if (child.isResource === isResource) {
                realChildren.push(childId);
            }
        });
        return realChildren;
    };
    window.ganttInstance.getTasksByServerId = (serverId) => {
        const tasks = window.ganttInstance.getTaskByTime();
        return tasks.filter(t => t.serverId === serverId) || [];
    }
    window.ganttInstance.getTaskByServerId = (serverId) => {
        const tasks = window.ganttInstance.getTaskByTime();
        return tasks.filter(t => t.serverId === serverId)[0] || null;
    }
    window.ganttInstance.updateTaskByServerId = (serverId, data) => {
        const allTasks = window.ganttInstance.getTaskByTime();
        const tasks = allTasks.filter(t => t.serverId === serverId);

        const updatedInfo = clone(data);
        window.ganttInstance.batchUpdate(() => {
            tasks.forEach((task) => {
                updatedInfo.id = task.id;
                if (isGroupement) {
                    updatedInfo.parent = task.parent;
                }
                // eslint-disable-next-line
                task.type = updatedInfo.type;
                window.ganttInstance.updateTask(task.id);
                window.ganttInstance.updateTask(task.id, updatedInfo);
            })
        })
        if (window.ganttInstance.ext.inlineEditors.isVisible()) {
            window.ganttInstance.ext.inlineEditors.focus();
        }
    }
    window.ganttInstance.getLinkByServerId = (serverId) => {
        const links = window.ganttInstance.getLinks();
        return links.filter((l) => l.serverId === serverId)[0] || null;
    };
    window.ganttInstance.deleteTaskByServerId = (serverId) => {
        const allTasks = window.ganttInstance.getTaskByTime();
        const tasks = allTasks.filter(t => t.serverId === serverId);
            tasks.forEach((task) => {
                window.ganttInstance.deleteTask(task.id);
            })
    }
};
export default {
    zoomLevels,
    scaleConfigs,
    GANTT_MIN_COLUMN_WIDTH,
    DEFAULT_COLUMNS,
    setGanttConfigurations,
};
