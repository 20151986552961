import moment from 'moment';
import { selectTabPreferences } from '../../redux/slices/app/tab.slice';
import store from '../../redux/store';
import { selectCalendarEntities } from '../../redux/slices/app/calendar.slice';
import { NEED_TYPE } from '../../constants/Needs';
import { isConflict } from './gantt_utils';
import { consumableIcon, nonConsumableIcon, warningBase64 } from '../../helpers/icons';

export const ganttColumnStartDateTemplate = (task) => {
    const state = store.getState();
    const tabPreferences = selectTabPreferences(state);
    return task.isExtra
        ? ''
        : moment(task.start_date).format(
              tabPreferences?.gantt_parameters?.hideHours || tabPreferences?.gantt_parameters?.hideHours === undefined
                  ? window.dateStringFormat
                  : window.dateStringFormat.concat(' HH:mm')
          );
};
export const ganttColumnEndDateTemplate = (task) => {
    const state = store.getState();
    const tabPreferences = selectTabPreferences(state);
    return task.isExtra
        ? ''
        : moment(task.end_date).format(
              tabPreferences?.gantt_parameters?.hideHours || tabPreferences?.gantt_parameters?.hideHours === undefined
                  ? window.dateStringFormat
                  : window.dateStringFormat.concat(' HH:mm')
          );
};

export const ganttColumnDurationTemplate = (task) =>
    task.isExtra || task.isResource ? '' : `${task.durationApi ? Number(task.durationApi).toFixed(1) : '0'}`;

const ganttTemplates = () => {
    const state = store.getState();
    const tabPreferences = selectTabPreferences(state);
    const calendarsDictionary = selectCalendarEntities(state);

    const ganttScaleCellClassTemplate = (date) => {
        const now = new Date();

        if (
            date.getFullYear() === now.getFullYear() &&
            date.getMonth() === now.getMonth() &&
            date.getDate() === now.getDate()
        ) {
            return 'current_day';
        }

        return '';
    };

    const ganttTimelineCellClassTemplate = (task, date) => {
        if (tabPreferences?.gantt_parameters?.showNoWorkingDay === false) {
            return '';
        }
        const now = new Date();

        if (
            date.getFullYear() === now.getFullYear() &&
            date.getMonth() === now.getMonth() &&
            date.getDate() === now.getDate()
        ) {
            return 'current_day';
        }
        let noWorkingPeriod = calendarsDictionary?.[task.calendarId]?.noWorkingDays ?? new Map();
        if (['quarter', 'year'].includes(window.ganttInstance.config.scaleName)) {
            noWorkingPeriod = calendarsDictionary?.[task.calendarId]?.noWorkingMonth ?? new Map();
        }
        if (window.ganttInstance.config.scaleName === 'month') {
            noWorkingPeriod = calendarsDictionary?.[task.calendarId]?.noWorkingWeek ?? new Map();
        }
        if (window.ganttInstance.config.scaleName === 'hours') {
            noWorkingPeriod = calendarsDictionary?.[task.calendarId]?.noWorkingHours ?? new Map();
        }
        const momento = moment(date).format('YYYY-MM-DD HH:mm:ss');
        const found = noWorkingPeriod[momento];

        if (found) {
            return 'no_working_day';
        }

        return '';
    };

    const ganttTaskTextTemplate = (start, end, task) => {
        if (window.ganttInstance) {
            const parameters = tabPreferences?.gantt_parameters;
            if (parameters?.centerTextColumn) {
                const column = window.ganttInstance.config.fullColumns.find(
                    (col) => col.id === parameters.centerTextColumn
                );
                let text = '';
                if (column && column.template) {
                    text = column.template(task) || '';
                } else {
                    text = task[parameters.centerTextColumn] || '';
                }
                if (parameters.centerTextColumn !== 'valid') {
                    return `<span class="px-2" title="${text}" style="color: ${
                        parameters.centerTextColor ?? 'black'
                    };">${text}</span>`;
                }
                return text;
            }
            return `<span class="px-2" title="${task.text}" >${task.text}</span>`;
        }
        return '';
    };

    const ganttTaskLeftTextTemplate = (start, end, task) => {
        if (window.ganttInstance) {
            const parameters = tabPreferences?.gantt_parameters;
            const isTaskConflict = isConflict(task);
            let text = ``;
            let html = ``;
            if (task.type === window.ganttInstance.config.types.milestone) {
                const column = window.ganttInstance.config.fullColumns.find(
                    (col) => col.id === parameters?.milestoneLeftTextColumn
                );
                if (column && column.template) {
                    text = column.template(task) || '';
                } else {
                    text = task[parameters?.milestoneLeftTextColumn] || '';
                }
                if (parameters?.milestoneLeftTextColumn && parameters?.milestoneLeftTextColumn !== 'valid') {
                    html =
                        text !== ''
                            ? `<span class="px-2 gantt_side_text" title="${text}" style="color: ${
                                  parameters.milestoneLeftTextColor ?? 'black'
                              };">${text}</span>`
                            : ``;
                }
            } else {
                const column = window.ganttInstance.config.fullColumns.find(
                    (col) => col.id === parameters?.leftTextColumn
                );
                if (column && column.template) {
                    text = text.concat(column.template(task) || '');
                } else {
                    text = task[parameters?.leftTextColumn] || '';
                }
                if (parameters?.leftTextColumn && parameters?.leftTextColumn !== 'valid') {
                    html =
                        text !== ''
                            ? `
                <span class="px-2 gantt_side_text" title="${text}" style="color: ${
                                  parameters.leftTextColor ?? 'black'
                              };">
                
                ${text}</span>`
                            : ``;
                }
            }
            return `<div style="display: flex; align-items: center; justify-content: start"> ${
                isTaskConflict
                    ? `<div style=" height: 20px; width: 20px; background-image: url(${warningBase64})" background-size: auto 100%; background-repeat: no-repeat;"></div>`
                    : ''
            }${html}</div>`;
        }
        return '';
    };

    const ganttTaskRightTextTemplate = (start, end, task) => {
        if (window.ganttInstance) {
            const parameters = tabPreferences?.gantt_parameters;
            let text = '';
            if (task.type === window.ganttInstance.config.types.milestone) {
                const column = window.ganttInstance.config.fullColumns.find(
                    (col) => col.id === parameters?.milestoneRightTextColumn
                );
                if (column && column.template) {
                    text = column.template(task) || '';
                } else {
                    text = task[parameters?.milestoneRightTextColumn] || '';
                }
                if (parameters?.milestoneRightTextColumn && parameters?.milestoneRightTextColumn !== 'valid') {
                    return text !== ''
                        ? `<div style="display: flex; align-items: center; justify-content: start"><span class="px-2 gantt_side_text" title="${text}" style="color: ${
                              parameters.milestoneLeftTextColor ?? 'black'
                          };">${text}</span></div>`
                        : ``;
                }
            } else {
                const column = window.ganttInstance.config.fullColumns.find(
                    (col) => col.id === parameters?.rightTextColumn
                );
                if (column && column.template) {
                    text = column.template(task) || '';
                } else {
                    text = task[parameters?.rightTextColumn] || '';
                }
                if (parameters?.rightTextColumn && parameters?.rightTextColumn !== 'valid') {
                    return text !== ''
                        ? `<div style="display: flex; align-items: center; justify-content: start"><span class="px-2 gantt_side_text" title="${text}" style="color: ${
                              parameters.rightTextColor ?? 'black'
                          };">${text}</span></div>`
                        : '';
                }
            }
            return text;
        }
        return '';
    };
    if (window.ganttInstance) {
        window.ganttInstance.templates.scale_cell_class = ganttScaleCellClassTemplate;
        window.ganttInstance.templates.timeline_cell_class = ganttTimelineCellClassTemplate;
        window.ganttInstance.templates.task_text = ganttTaskTextTemplate;
        window.ganttInstance.templates.leftside_text = ganttTaskLeftTextTemplate;
        window.ganttInstance.templates.rightside_text = ganttTaskRightTextTemplate;
        // grid class coloration
        const colorTemplate = (start, end, task) => {
            if (task.isResource) {
                const taskParent = window.ganttInstance.getTask(task.parent);
                if (taskParent?.type === 'task' || taskParent?.type === 'milestone') {
                    return `simple-task gantt-resource-task resource-task-row-${task.id}`;
                }
                if (taskParent?.type === 'custom-project') {
                    return `level-${taskParent.$level} gantt-resource-task`;
                }
            }
            if (window.ganttInstance.getAllChildren(task.id).length > 0) {
                return `level-${task.$level} gantt_row_project`;
            }
            if (task.virtual) {
                return '';
            }
            return 'simple-activity';
        };
        window.ganttInstance.templates.grid_row_class = colorTemplate;
        window.ganttInstance.templates.task_row_class = colorTemplate;

        // hide or show links
        window.ganttInstance.templates.link_class = (link) => {
            const parameters = tabPreferences?.gantt_parameters;
            if (parameters) {
                return parameters.showLinks === false ? 'hidden' : `link-id-${link.serverId}`;
            }
            return `link-id-${link.serverId}`;
        };

        window.ganttInstance.templates.task_class = (start, end, task) => {
            if (task.isResource) return 'hidden';
            const parameters = tabPreferences?.gantt_parameters;
            const classes = [];
            // Critical Path Shadow
            if (parameters?.splitTask) {
                classes.push('box-shadow');
            }
            // gantt project
            if (task.type === window.ganttInstance.config.types.customProject) {
                classes.push('gantt_project');
            }
            // Fix : display critical path on leaf node or on node if it s closed
            if (task.totalMargin === task.endDate && (!task.$open || !window.ganttInstance.hasChild(task.id))) {
                classes.push('critical-task');
            }

            if (window.ganttInstance.isSplitTask(task)) {
                classes.push('is_split_task');
            }
            if (task.hidden) {
                classes.push('hidden');
            }
            // adding custom classes to gantt-task
            // if (parseInt(task.duration, 10) !== 0) {
            classes.push(`task-id-${task.serverId ?? task.id}`);
            // }
            return classes.join(' ');
        };

        window.ganttInstance.templates.grid_file = (task) => {
            if (task.isResource) {
                const icon = task.needType === NEED_TYPE.NON_CONSUMMABLE ? nonConsumableIcon : consumableIcon;
                return `<div class='gantt_tree_icon' style="opacity: 0.6; width: 20px; background-image: url(${icon});"></div>`;
            }

            if (task?.calendarId && calendarsDictionary[task.calendarId]?.noWorkingReals) {
                // Check if activity is on unworked calendar time; its happens if calendar has been modified after the activity placement
                const workOnNoWorkingTime = calendarsDictionary[task.calendarId].noWorkingReals.find(
                    (slice) =>
                        (slice.start < task.startDate && task.startDate < slice.end) ||
                        (slice.start < task.endDate && task.endDate < slice.end)
                );
                if (workOnNoWorkingTime)
                    return `<div class="gantt_tree_icon" style="background-image: url(${warningBase64});"></div>`;
            }
            if (task.isExtra) return '';
            return "<div class='gantt_tree_icon gantt_file'></div>";
        };
        window.ganttInstance.templates.grid_folder = (task) => {
            // make icon warning when has conflict incoming link
            const isLockConflict = isConflict(task);
            if (isLockConflict && task.taskType === 'task') {
                return `<div class="gantt_tree_icon" style="background-image: url(${warningBase64});"></div>`;
            }
            return `<div class='gantt_tree_icon ${
                task.realChildren?.length > 0 ? 'gantt_folder_closed' : 'gantt_file'
            }'></div>`;
        };
    }
};

export default ganttTemplates;
