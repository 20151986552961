import {createGlobalStyle} from 'styled-components';

const SimpleTaskColor = createGlobalStyle`
.simple-activity {
    ${(props) => (props.show ? `background: ${props.color} !important;` : '')} 
    ${(props) => (props.show ? `color: ${props.fontColor} !important;` : '')} 
    
}

.simple-task.gantt-resource-task {
    ${(props) =>
        props.show && props.color
            ? `background: ${props.color.concat(40)} !important;
    color: black !important;
    `
            : ''} 
    
}

.simple-activity .gantt_tree_content {
    ${(props) => (props.fontSize && props.show ? `font-size: ${props.fontSize} !important;` : '')} 
    ${(props) => (props.fontStyle === 'bold' && props.show ? `font-weight: 700 !important;` : '')} 
    ${(props) => (props.fontStyle === 'italic' && props.show ? `font-style: ${props.fontStyle} !important;` : '')} 
    ${(props) =>
        props.fontStyle === 'bold-italic' && props.show
            ? `font-style: italic !important; font-weight: 700 !important;`
            : ''} 
}
`;
export default SimpleTaskColor;