import { selectTimeUnitById } from "../../redux/slices/app/calendar.slice";
import store from "../../redux/store";
import quantityUnits from "./activity_config";


const getDurationUnitName = (id) => {
    const timeUnit = selectTimeUnitById(store.getState(), id);
    return timeUnit ? timeUnit.name : '-';
};

export const getDurationUnit = (id) => {
    const timeUnit = selectTimeUnitById(store.getState(), id);
    return timeUnit;
};

export const getQuantityUnitName = (id) => {
    const quantityUnit = quantityUnits.find((time) => time.value === id);
    return quantityUnit ? quantityUnit.label : '-';
};

export const getYieldUnit = (quantityId, durationId) =>
    `${getQuantityUnitName(quantityId)}/${getDurationUnitName(durationId)}`;

export const isConflict = (task) => {
    const sourcelinks = task.$source.map((linkId) => ({
        categoryId: window.ganttInstance.getLink(linkId)?.dataAPI?.category?.id,
        ...window.ganttInstance.getLink(linkId),
    }));
    let isTaskConflict = false;
    sourcelinks.forEach((link) => {
        if (
            link.categoryId === -2 &&
            window.ganttInstance.isTaskExists(link.target) &&
            window.ganttInstance.getTask(link.target)
            // Fonctionnalité desactivé temporairement
            // &&
            // window.ganttInstance.getTask(link.target).locked
        ) {
            isTaskConflict = true;
        }
    });
    // Fonctionnalité desactivé temporairement
    // if (task.status === ACTIVITY_STATE.VALID) {
    // make icon warning when has conflict incoming link
    const targetlinksCategories = task.$target.map(
        (linkId) => window.ganttInstance.getLink(linkId)?.dataAPI?.category?.id
    );
    if (targetlinksCategories.filter((i) => i === -2).length > 0) {
        isTaskConflict = true;
    }
    // }
    return isTaskConflict;
};

export const addVirtualTasks = (tabPreferences) => {
    const ganttParameters = tabPreferences?.gantt_parameters;
    if (ganttParameters?.showProgressLine || (tabPreferences?.gantt_markers ?? []).filter((i) => i.show).length) {
        if (window.ganttInstance.isTaskExists('virtual_top')) {
            window.ganttInstance.deleteTask('virtual_top');
            window.ganttInstance.deleteTask('virtual_bottom');
        }
        const virtualTask = {
            id: 'virtual_top',
            text: '',
            type: 'task',
            virtual: true,
            isExtra: true, // indicateur des lignes ajoutées
            unscheduled: true,
            parent: 0,
            row_height: 30,
            bar_height: 25,
        };
        // ajout des tâches virtuelles
        window.ganttInstance.addTask({ ...virtualTask }, 0, 0);
        window.ganttInstance.addTask({ ...virtualTask, id: 'virtual_bottom' }, 0);
    }
};

export default getDurationUnitName;