import {createGlobalStyle} from 'styled-components';

const TaskLevelColor = createGlobalStyle`
.simple-activity .gantt_cell_tree .gantt_tree_indent:nth-of-type(${(props) =>
    +props.level + 1}), .gantt_row_project .gantt_cell_tree .gantt_tree_indent:nth-of-type(${(props) =>
    +props.level + 1}), .gantt-resource-task .gantt_tree_indent:nth-of-type(${(props) => +props.level + 1}), .level-${(
    props
) => props.level} {
    ${(props) => (props.show ? `background: ${props.color} !important;` : '')} 
    ${(props) => (props.show ? `color: ${props.fontColor} !important;` : '')} 
    
}

.level-${(props) => props.level}.gantt-resource-task {
    ${(props) =>
        props.show
            ? `background: ${props.color.concat(40)} !important;
    color: black !important;
    `
            : ''} 
    
}
.level-${(props) => props.level} .gantt_tree_content {
    ${(props) => (props.fontSize && props.show ? `font-size: ${props.fontSize} !important;` : '')} 
    ${(props) => (props.fontStyle === 'bold' && props.show ? `font-weight: 700 !important;` : '')} 
    ${(props) => (props.fontStyle === 'italic' && props.show ? `font-style: ${props.fontStyle} !important;` : '')} 
    ${(props) =>
        props.fontStyle === 'bold-italic' && props.show
            ? `font-style: italic !important; font-weight: 700 !important;`
            : ''} 
}
`;

export default TaskLevelColor;