import {createGlobalStyle} from 'styled-components';
import { getTextColor } from '../../../helpers/text-color';

const TaskResourceColor = createGlobalStyle`
.resource-task-${(props) => props.name.replace(/ /g, '')} {
    ${(props) => `background: ${props.color} !important; color: ${getTextColor(props.color)};`} 
    
}
.resource-subtask-${(props) => props.name.replace(/ /g, '')} {
    ${(props) => `background: ${props.color.concat('90')} !important;`} 
    
}
`;
export default TaskResourceColor;