
import styled from 'styled-components';

const GanttWrapper = styled.div`
    height: 100%;
    width: 100%;
    .gantt_grid_scale {
        color: #2a2f35;
        background-color: #f8f8f8;
    }

    .gantt_grid_scale .gantt_grid_head_cell {
        font-weight: bold;
        opacity: 1;
        color: #2a2f35;
        background-color: #f8f8f8;
    }

    .gantt_grid_scale > .gantt_grid_head_add {
        background-image: none;
        pointer-events: none;
    }

    .gantt_task_line {
        &.gantt_milestone {
            background-color: #2a2f35;
            border: 0;
        }

        &.no_link {
            &.gantt_milestone {
                background-color: #fbbe3f;
            }

            .gantt_link_control {
                display: none;
            }
        }
    }
    ${(props) =>
        props.hasBaseLine
            ? `
    .gantt_task_line, .gantt_line_wrapper, .freeSlack, .totalSlack {
        ${props.marginTop ? 'margin-top:-'.concat(props.marginTop, 'px;') : ''}
    }
    .gantt_task_link .gantt_link_arrow {
        ${props.marginTop ? 'margin-top:-'.concat(props.marginTop + 3, 'px;') : ''}
    }
    `
            : ``}
`;
export default GanttWrapper;