// custom editors
/* eslint-disable */
const ganttCustomEditors = () => {
    switch (localStorage.getItem('lng')) {
        case 'fr':
            $.datepicker.setDefaults($.datepicker.regional['fr']);
            $.timepicker.setDefaults($.timepicker.regional['fr']);
            break;
        case 'en':
            $.datepicker.setDefaults($.datepicker.regional['en']);
            $.timepicker.setDefaults($.timepicker.regional['en']);
            break;

        default:
            $.datepicker.setDefaults($.datepicker.regional['fr']);
            $.timepicker.setDefaults($.timepicker.regional['fr']);
            break;
    }
    window.ganttInstance.config.editor_types.datetime_editor = {
        show: function (id, column, config, placeholder) {
            placeholder.innerHTML = "<div><input type='text' id='datepicker' name='" + column.name + "'></div>";
            $('#datepicker').datetimepicker({
                dateFormat: window.datepickerFormat,
                controlType: 'select',
                oneLine: true,
                timeFormat: 'HH:mm',
                showButtonPanel: false,
                onSelect: function (dateStr) {
                    window.ganttInstance.ext.inlineEditors.save();
                },
            });
        },
        hide: function (node) {
            $('#ui-datepicker-div').hide();
            $('#datepicker').datetimepicker('destroy');
        },

        set_value: function (value, id, column, node) {
            $('#datepicker').datetimepicker('setDate', value);
        },

        get_value: function (id, column, node) {
            return $('#datepicker').datetimepicker('getDate');
        },

        is_changed: function (value, id, column, node) {
            return +$('#datepicker').datetimepicker('getDate') !== +value;
        },
        is_valid: function (value, id, column, node) {
            return !isNaN(+$('#datepicker').datetimepicker('getDate'));
        },
        save: function (id, column, node) {},
        focus: function (node) {},
    };

    window.ganttInstance.config.editor_types.custom_datepicker_editor = {
        show: function (id, column, config, placeholder) {
            placeholder.innerHTML = "<div><input type='text' id='datepicker' name='" + column.name + "'></div>";
                $("#datepicker").datepicker({
                    dateFormat: window.datepickerFormat,
                    onSelect: function(dateStr){
                        window.ganttInstance.ext.inlineEditors.save()
                    }
                });
        },
        hide: function (node) {
            $("#datepicker").datepicker( "destroy" );
            
        },
    
        set_value: function (value, id, column, node) {
            $("#datepicker").datepicker("setDate", value);
        },
    
        get_value: function (id, column, node) {
            return $("#datepicker").datepicker( "getDate" );
        },
    
        is_changed: function (value, id, column, node) {
            return (+$("#datepicker").datepicker( "getDate" ) !== +value);
        },
        is_valid: function (value, id, column, node) {
            return !(isNaN(+$("#datepicker").datepicker( "getDate" )))
        },
        save: function (id, column, node) {
        },
        focus: function (node) {
        }
    };
    window.ganttInstance.config.editor_types.progress_editor = {
        show: (id, column, config, placeholder) => {
            const min = config.min || 0;
            const max = config.max || 100;

            const html =
                "<div><input type='number' min='" + min + "' max='" + max + "' name='" + column.name + "'></div>";
            placeholder.innerHTML = html;
        },
        hide: () => {
            // can be empty since we don't have anything to clean up after the editor
            // is detached
        },
        set_value: (value, id, column, node) => {
            node.querySelector('input').value = value;
        },
        get_value: (id, column, node) => {
            let val = node.querySelector('input').value;
            if (val < 0) val = 0;
            if (val > 100) val = 100;
            return val;
        },
        is_changed: (value, id, column, node) => {
            const currentValue = node.firstChild.firstChild.value;
            return Number(value) !== Number(currentValue);
        },
        is_valid: (value, id, column, node) => {
            return !isNaN(parseInt(value, 10));
        },
        focus: (node) => {
            const input = node.firstChild.firstChild;
            if (!input) {
                return;
            }
            if (input.focus) {
                input.focus();
            }

            if (input.select) {
                input.select();
            }
        },
    };
    window.ganttInstance.config.editor_types.quantity_editor = {
        show: (id, column, config, placeholder) => {
            const selectOptions = config.selectOptions.reduce(
                (prev, current) => `${prev} <option value="${current.value}">${current.label}</option>`,
                ''
            );
            const html =
                "<div style='width: 100%; display: flex;'><input style='width: 60%;' id='quantityInput' type='number' min='0'><select style='width: 40%;' id='quantityUnitInput'>" +
                selectOptions +
                '</select></div>';
            placeholder.innerHTML = html;
        },
        hide: () => {
            // can be empty since we don't have anything to clean up after the editor
            // is detached
        },
        set_value: (value, id, column, node) => {
            const task = window.ganttInstance.getTask(id);
            node.querySelector('#quantityInput').value = task.quantity;
            node.querySelector('#quantityUnitInput').value = task.quantityUnit;
        },
        get_value: (id, column, node) => {
            return `${node.querySelector('#quantityInput').value}-${node.querySelector('#quantityUnitInput').value}`;
        },
        is_changed: (value, id, column, node) => {
            const task = window.ganttInstance.getTask(id);
            const quantityValue = node.querySelector('#quantityInput').value;
            const quantityUnitValue = node.querySelector('#quantityUnitInput').value;
            return Number(task.quantity) !== Number(quantityValue) || task.quantityUnit !== quantityUnitValue;
        },
        is_valid: (value, id, column, node) => {
            return true;
        },
        focus: (node) => {
            const input = node.firstChild.firstChild;
            if (!input) {
                return;
            }
            if (input.focus) {
                input.focus();
            }

            if (input.select) {
                input.select();
            }
        },
    };

    window.ganttInstance.config.editor_types.custom_text_editor = {
        show: (id, column, config, placeholder) => {

            const html =
                "<div><input style='padding: 2px;' type='text' name='" + column.name + "'></div>";
            placeholder.innerHTML = html;
        },
        hide: () => {
            // can be empty since we don't have anything to clean up after the editor
            // is detached
        },
        set_value: (value, id, column, node) => {
            node.querySelector('input').value = value || '';
        },
        get_value: (id, column, node) => {
            let val = node.querySelector('input').value;
            return val;
        },
        is_changed: (value, id, column, node) => {
            const currentValue = node.firstChild.firstChild.value;
            return value !== currentValue;
        },
        is_valid: (value, id, column, node) => {
            return true;
        },
        focus: (node) => {
            const input = node.firstChild.firstChild;
            if (!input) {
                return;
            }
            if (input.focus) {
                input.focus();
            }
        },
    };
};

export default { ganttCustomEditors };
